<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'orders-suppliers-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
    </div>
    <br />

    <vue-data-table 
      :headers="headers" 
      :data="orders" 
      :items-per-page="10" 
      :buttons="buttons"
      :summable-columns="summableColumns"
    />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      headers: [
        { label: 'Référence', field: 'reference' },
        { label: 'Fournisseur', field: 'supplierSociety' },
        { label: 'Date', field: 'date' },
        { label: 'Montant Total', field: 'totalPriceTTC' },
        { label: 'Total Quantité', field: 'totalQuantity' },
        { label: 'Créer par', field: 'userFullName' },
      ],
      buttons: [
        {
          label: 'Détails',
          icon: 'bi bi-info-square',
          class: 'btn btn-primary',
          action: (row) => {
            this.$router.push({
              name: 'orders-suppliers-details',
              params: { reference: row.reference },
            });
          },
        },
      ],
      summableColumns: [
        { field: 'totalPriceTTC' },
        { field: 'totalQuantity' },
      ],
    };
  }, 
  computed: {
    ...mapGetters("order", {
      orders: "getOrderSuppliers",
    }),
  },
  mounted() {
    this.$store.dispatch("order/fetchSupplierOrders");

  },
  methods: {
    async search(value) {
      return await this.$store.commit("order/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("order/fetchSupplierOrders");
    },
  },
};
</script>
